<template>
  <div
    class="form-group"
    :class="{
      'text-left': !isRight,
      'text-right': isRight,
    }"
    role="group"
  >
    <div>
      <label
        v-if="label && !this.$slots.label"
        :for="id"
        :class="{ 'text-danger': state === false }"
      >
        {{ label }} <span v-if="isRequired" class="text-danger pl-1">*</span>
      </label>
      <slot name="label" />

      <popper
        v-if="popoverText || $slots['popover']"
        trigger="hover"
        :options="{
          placement: isRight ? 'right' : 'left',
        }"
      >
        <div class="popper">
          <div class="img-fluid-container">
            <slot name="popover"></slot>
          </div>
          {{ popoverText }}
        </div>

        <span
          slot="reference"
          class="far fa-question-circle cursor-pointer d-inline-block"
          :class="{
            'mr-2': isRight,
            'mr-1 ml-0': !isRight,
          }"
        />
      </popper>
    </div>

    <b-form-textarea
      :id="id"
      :type="type"
      v-model="internalValue"
      :state="state"
      :aria-describedby="`${id}-live-help ${id}-feedback`"
      :placeholder="placeholder"
      :disabled="disabled"
      :rows="rows"
      :maxRows="maxRows"
      :maxlength="maxlength"
    />

    <b-form-invalid-feedback
      v-if="error && !this.$slots.error"
      :id="`${id}-feedback`"
    >
      {{ error }}
    </b-form-invalid-feedback>

    <slot name="error" />
  </div>
</template>

<script>
import Popper from "vue-popperjs";

export default {
  name: "VTextarea",

  data() {
    return {
      internalValue: this.value,
    };
  },

  props: {
    isRight: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      isRequired: true,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: "text",
    },
    rows: {
      type: Number,
      default: 6,
    },
    maxRows: {
      type: Number,
      default: 6,
    },
    maxlength: {
      type: Number,
      default: 2000,
    },
    placeholder: {
      type: String,
      default: null,
    },
    value: {
      type: String,
      default: "",
    },
    state: {
      type: Boolean,
      default: undefined,
    },
    error: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: undefined,
    },
    popoverText: {
      type: String,
      default: null,
    },
  },

  watch: {
    internalValue(val) {
      this.$emit("onChange", val);
    },

    value(val) {
      this.internalValue = val;
    },
  },

  components: {
    popper: Popper,
  },
};
</script>
