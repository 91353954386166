<template>
  <div class="product-table">
    <header class="product-table-head">
      <div class="product-table-row">
        <div class="product-table-cell">
          {{ $t("product.list.column.product") }}
        </div>

        <div class="product-table-cell">
          {{ $t("product.list.column.serial") }}
        </div>
      </div>
    </header>

    <div
      v-if="Array.isArray(products) && products.length"
      class="product-table-body"
    >
      <div
        class="product-table-row"
        v-for="(product, index) in products"
        :key="index"
      >
        <div class="product-table-cell">
          <span class="product-mobile-label"
            >{{ $t("product.list.column.product") }}
          </span>
          <div>{{ product.name || product.itemName }}</div>
        </div>
        <div
          class="product-table-cell"
          v-if="Array.isArray(product.serials) && product.serials.length"
        >
          <span class="product-mobile-label"
            >{{ $t("product.list.column.serial") }}
          </span>
          <v-tooltip :title="product.serials.length">{{
            product.serials.join(", ")
          }}</v-tooltip>
        </div>
        <div
          class="product-table-cell"
          v-else-if="
            Array.isArray(product.serialNumber) && product.serialNumber.length
          "
        >
          <span class="product-mobile-label"
            >{{ $t("product.list.column.serial") }}
          </span>
          <v-tooltip :title="product.serialNumber.length">{{
            product.serialNumber.join(", ")
          }}</v-tooltip>
        </div>
        <div class="product-table-cell" v-else>
          <span class="product-mobile-label"
            >{{ $t("product.list.column.serial") }}
          </span>
          <span>{{ product.serialNumber }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VTooltip from "@/components/VTooltip";

export default {
  name: "VProductTable",

  components: {
    VTooltip,
  },

  props: {
    isRight: {
      type: Boolean,
      default: false,
    },
    products: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    handleChange(name, serial) {
      if (serial) this.$emit("onChange", { name, serial });
      else this.$emit("onChange", { name: null, serial: null });
    },
  },

  computed: {
    dir() {
      return this.isRight ? "rtl" : "ltr";
    },
  },
};
</script>

<style lang="scss" scoped>
.product-table,
.product-table-head,
.product-table-body,
.product-table-row {
  display: flex;
  width: 100%;
}

.product-table,
.product-table-body {
  flex-direction: column;
}

.product-table {
  .v-select {
    max-width: 320px;
  }
}

.product-table-head,
.product-table-body {
  font-size: 14px;
}

.product-table-head {
  display: none;

  @media (min-width: 800px) {
    display: block;
    color: #00406b;
  }
}

.product-table-body {
  color: #2d2d2d;
}

.product-table-head {
  .product-table-row {
    border-bottom: 2px solid #f1f1f1;
  }
}

.product-table-body {
  .product-table-row {
    border: 2px solid #f1f1f1;
  }
}

.product-table-row {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 5px;

  @media (min-width: 800px) {
    flex-direction: row;
    margin-bottom: 15px;
  }
}

.product-table-cell {
  padding: 8px 10px;
  width: 100%;

  @media (min-width: 800px) {
    display: flex;
    width: 50%;
  }

  button,
  button:hover {
    background: transparent;
    border: 0;
    cursor: pointer;
  }

  .form-group,
  .form-inline,
  .form-check {
    margin-bottom: 0;
    width: 100%;

    @media only screen and (min-width: 900px) {
      width: 50%;
    }
  }
}

.product-mobile-label {
  display: block;
  color: #00406b;

  @media (min-width: 800px) {
    display: none;
  }
}
</style>
