<template>
  <v-form-card id="pickup-address-form" :isNested="false" :isRight="isRight">
    <h5 class="d-flex mb-5">
      <img
        class="img-icon d-flex"
        :class="{
          'mr-2': !isRight,
          'ml-2': isRight,
        }"
        src="@/assets/images/icon-location.png"
        alt="img-location"
      />
      <span class="card-small-title d-flex">{{
        $t("label.pickup-address-form.form.title")
      }}</span>
    </h5>

    <v-input
      :isRight="isRight"
      id="pickup-adress-street-number"
      :isRequired="true"
      :label="$t('label.pickup-address.street')"
      :state="statePickupAddressStreet"
      :error="$t('error.required')"
      @onChange="(val) => handleChange('pickupAddressStreet', val)"
    />

    <v-input
      :isRight="isRight"
      id="pickup-adress-city-number"
      :isRequired="true"
      :label="$t('label.pickup-address.city')"
      :state="statePickupAddressCity"
      :error="$t('error.required')"
      @onChange="(val) => handleChange('pickupAddressCity', val)"
    />

    <v-input
      :isRight="isRight"
      id="pickup-adress-postal-code-number"
      :isRequired="true"
      :label="$t('label.pickup-address.postal-code')"
      :state="statePickupAddressPostalCode"
      @onChange="(val) => handleChange('pickupAddressPostalCode', val)"
    >
      <div slot="error">
        <slot name="pickupAddressPostalCodeError" />
      </div>
    </v-input>

    <v-select
      :isRight="isRight"
      id="pickup-adress-country-number"
      :isRequired="true"
      :label="$t('label.pickup-address.country')"
      :state="statePickupAddressCountry"
      :error="$t('error.required')"
      :options="countriesArr"
      @onChange="(val) => handleChange('pickupAddressCountry', val)"
    />
  </v-form-card>
</template>

<script>
import VFormCard from "@/components/VFormCard";
import VInput from "@/components/VInput";
import VSelect from "@/components/VSelect";

export default {
  name: "VPickupAddressForm",

  components: {
    VFormCard,
    VInput,
    VSelect,
  },

  data() {
    return {
      pickupAddressStreet: null,
      pickupAddressCity: null,
      pickupAddressPostalCode: null,
      pickupAddressCountry: null,
    };
  },

  props: {
    isRight: {
      type: Boolean,
      default: true,
    },
    statePickupAddressStreet: {
      type: Boolean,
      default: undefined,
    },
    statePickupAddressCity: {
      type: Boolean,
      default: undefined,
    },
    statePickupAddressPostalCode: {
      type: Boolean,
      default: undefined,
    },
    statePickupAddressCountry: {
      type: Boolean,
      default: undefined,
    },
    countriesArr: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    handleChange(name, value) {
      this[name] = value;
      this.$emit("onChange", name, value);
    },
  },
};
</script>
