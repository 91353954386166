<template>
  <v-form-card id="contact-form" :isNested="false" :isRight="isRight">
    <h5 class="d-flex mb-5">
      <img
        class="img-icon d-flex"
        :class="{
          'mr-2': !isRight,
          'ml-2': isRight,
        }"
        src="@/assets/images/icon-person.png"
        alt="img-person"
      />

      <span class="card-small-title d-flex"
        >{{ $t("label.contact.form.title") }}
      </span>
    </h5>

    <v-input
      :isRight="isRight"
      id="contact-name"
      :isRequired="true"
      :label="$t('label.contact.form.name')"
      :state="stateContactName"
      :error="$t('error.required')"
      @onChange="(val) => handleChange('contactName', val)"
    />

    <v-input
      :isRight="isRight"
      id="contact-surname"
      :isRequired="true"
      :label="$t('label.contact.form.surname')"
      :state="stateContactSurname"
      :error="$t('error.required')"
      @onChange="(val) => handleChange('contactSurname', val)"
    />

    <div class="form-group mb-0 row">
      <div class="col-12">
        <label
          for="contact-name"
          :class="{
            'text-danger': isPhoneError,
          }"
        >
          {{ $t("label.contact.form.phone") }}
          <span class="text-danger pl-1">*</span>
        </label>
      </div>

      <div class="col-4 fake-plus-container">
        <span class="fake-plus">+</span>
        <v-input
          :isRight="isRight"
          :isRequired="true"
          id="contact-phone-prefix"
          type="tel"
          :min="0"
          :state="stateContactPhonePrefix"
          :error="$t('error.required')"
          @onChange="
            (val) => handleChange('contactPhonePrefix', val.replace(/\+/g, ''))
          "
        />
      </div>

      <div class="col-8">
        <v-input
          :isRight="isRight"
          id="contact-phone"
          type="tel"
          :min="0"
          :isRequired="true"
          :state="stateContactPhone"
          :error="$t('error.required')"
          @onChange="(val) => handleChange('contactPhone', val)"
        />
      </div>
    </div>

    <v-input
      :isRight="isRight"
      id="contact-email"
      :isRequired="true"
      :label="$t('label.contact.form.email')"
      :state="stateContactEmail"
      @onChange="(val) => handleChange('contactEmail', val)"
    >
      <div slot="error">
        <slot name="contactEmailError" />
      </div>
    </v-input>
  </v-form-card>
</template>

<script>
import VFormCard from "@/components/VFormCard";
import VInput from "@/components/VInput";

export default {
  name: "VContactForm",

  components: {
    VFormCard,
    VInput,
  },

  data() {
    return {
      contactName: null,
      contactSurname: null,
      contactEmail: null,
      contactPhonePrefix: null,
      contactPhone: null,
    };
  },

  props: {
    isRight: {
      type: Boolean,
      default: true,
    },
    stateContactName: {
      type: Boolean,
      default: undefined,
    },
    stateContactSurname: {
      type: Boolean,
      default: undefined,
    },
    stateContactEmail: {
      type: Boolean,
      default: undefined,
    },
    stateContactPhonePrefix: {
      type: Boolean,
      default: undefined,
    },
    stateContactPhone: {
      type: Boolean,
      default: undefined,
    },
  },

  methods: {
    handleChange(name, value) {
      this[name] = value;
      this.$emit("onChange", name, value);
    },
  },

  computed: {
    isPhoneError() {
      return (
        this.stateContactPhonePrefix === false ||
        this.stateContactPhone === false
      );
    },
  },
};
</script>
