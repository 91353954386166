<template>
  <div
    role="group"
    class="form-group product-condition text-danger"
    :class="{
      'text-left': !isRight,
      'text-right': isRight,
    }"
    id="product-condition"
  >
    <label :class="{ 'text-danger': state }"
      ><i v-if="state" class="fas fa-exclamation-triangle"></i>
      {{ $t("label.reason.sales.state") }}
      <span class="text-danger pl-1">*</span></label
    >

    <b-form-group v-slot="{ ariaDescribedby }">
      <b-form-radio
        v-model="selected"
        :aria-describedby="ariaDescribedby"
        name="new-radios"
        value="NEW"
        :state="state"
        :class="{ 'is-invalid': state }"
        >{{ $t("label.reason.sales.state.new") }}</b-form-radio
      >
      <b-form-radio
        v-model="selected"
        :aria-describedby="ariaDescribedby"
        name="open-packing-radios"
        value="OPEN"
        :state="state"
        :class="{ 'is-invalid': state }"
        >{{ $t("label.reason.sales.state.open") }}</b-form-radio
      >
      <b-form-radio
        v-model="selected"
        :aria-describedby="ariaDescribedby"
        name="no-orginal-packing-radios"
        value="__NO_ORIGINAL_PACKAGING__"
        :state="state"
        :class="{ 'is-invalid': state }"
        >{{ $t("label.reason.sales.state.no.orginal.packing") }}</b-form-radio
      >
    </b-form-group>
  </div>
</template>

<script>
export default {
  name: "VProductCondition",

  data() {
    return {
      selected: this.value,
    };
  },

  props: {
    isRight: {
      type: Boolean,
      default: false,
    },
    state: {
      type: Boolean,
      default: undefined,
    },
    value: {
      type: String,
      default: "",
    },
  },

  watch: {
    selected(val) {
      this.$emit("onSelect", val);
    },

    value(val) {
      this.selected = val;
    },
  },

  computed: {
    title() {
      return this.$t("label.reason.sales.state");
    },
  },
};
</script>

<style lang="scss">
.product-condition {
  label {
    margin-bottom: 10px !important;
  }

  .custom-control-input.is-invalid ~ .custom-control-label,
  .was-validated .custom-control-input:invalid ~ .custom-control-label {
    color: initial;
  }

  .custom-control-input.is-invalid ~ .custom-control-label::before,
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before {
    border-color: #adb5bd;
  }

  .custom-control-input.is-invalid:checked ~ .custom-control-label::before,
  .was-validated
    .custom-control-input:invalid:checked
    ~ .custom-control-label::before {
    border-color: #007bff;
    background-color: #007bff;
  }

  /* .form-group label,
  .form-inline label,
  .form-check label {
    color: initial;
  }

  .custom-control-input.is-invalid ~ .custom-control-label,
  .was-validated .custom-control-input:invalid ~ .custom-control-label {
    color: initial;
  }

  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #007bff !important;
    background-color: #007bff !important;
} */
}
</style>
