var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-group",class:{
    'text-left': !_vm.isRight,
    'text-right': _vm.isRight,
  },attrs:{"role":"group"}},[_c('div',[(_vm.label && !this.$slots.label)?_c('span',{class:{ 'text-danger': _vm.state === false },attrs:{"for":_vm.id}},[_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.isRequired)?_c('span',{staticClass:"text-danger pl-1"},[_vm._v("*")]):_vm._e()]):_vm._e(),_vm._t("label"),(_vm.popoverText || _vm.$slots['popover'])?_c('popper',{attrs:{"trigger":"hover","options":{
        placement: _vm.isRight ? 'right' : 'left',
      }}},[_c('div',{staticClass:"popper"},[_c('div',{staticClass:"img-fluid-container"},[_vm._t("popover")],2),_vm._v(" "+_vm._s(_vm.popoverText)+" ")]),_c('span',{staticClass:"far fa-question-circle cursor-pointer d-inline-block",class:{
          'mr-2': _vm.isRight,
          'mr-1 ml-0': !_vm.isRight,
        },attrs:{"slot":"reference"},slot:"reference"})]):_vm._e()],2),_c('div',{staticClass:"file-field",class:{ 'file-field-disabled': _vm.isDisabled }},[_c('label',{attrs:{"for":_vm.id}},[_vm._v(_vm._s(_vm.$t("product.form.drag.drop.file"))+" "),_c('span',{staticClass:"text-info"},[_vm._v(_vm._s(_vm.$t("product.form.browse")))])]),_c('b-form-file',{attrs:{"id":_vm.id,"state":_vm.state,"aria-describedby":(_vm.id + "-live-help " + _vm.id + "-feedback"),"placeholder":_vm.placeholder,"disabled":_vm.isDisabled,"accept":_vm.accept.join(', '),"multiple":"","plain":""},on:{"input":_vm.handleInput}})],1),(_vm.error && !this.$slots.error)?_c('b-form-invalid-feedback',{attrs:{"id":(_vm.id + "-feedback")}},[_vm._v(" "+_vm._s(_vm.error)+" ")]):_vm._e(),_vm._t("error"),(_vm.internalValue.length)?_c('div',{staticClass:"uploaded-files"},_vm._l((_vm.internalValue),function(file,index){return _c('div',{key:index,staticClass:"uploaded-file"},[_vm._v(" "+_vm._s(file.name)+" "),_c('span',{staticClass:"text-danger",on:{"click":function () { return _vm.handleRemove(file); }}},[_vm._v("✕")])])}),0):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }