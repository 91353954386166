<template>
  <v-form-card id="return-address-form" :isNested="false" :isRight="isRight">
    <h5 class="d-flex">
      <img
        class="img-icon d-flex"
        :class="{
          'mr-2': !isRight,
          'ml-2': isRight,
        }"
        src="@/assets/images/icon-return.png"
        alt="img-return"
      />

      <span class="card-small-title d-flex">{{
        $t("label.return-address.form.form-title")
      }}</span>
    </h5>

    <b-form-checkbox
      id="same-address"
      name="same-address"
      class="mb-3"
      :value="true"
      :unchecked-value="false"
      @change="handleCheck"
    >
      {{ $t("label.return-address.copy-from-pickup") }}
    </b-form-checkbox>

    <v-input
      :isRight="isRight"
      id="return-adress-street-number"
      :isRequired="true"
      :label="$t('label.return-address.street')"
      :value="returnAddressStreet"
      :state="stateReturnAddressStreet && (!sameAddress || undefined)"
      :error="$t('error.required')"
      :disabled="sameAddress"
      @onChange="(val) => handleChange('returnAddressStreet', val)"
    />

    <v-input
      :isRight="isRight"
      id="return-adress-city-number"
      :isRequired="true"
      :label="$t('label.return-address.city')"
      :value="returnAddressCity"
      :state="stateReturnAddressCity && (!sameAddress || undefined)"
      :error="$t('error.required')"
      :disabled="sameAddress"
      @onChange="(val) => handleChange('returnAddressCity', val)"
    />

    <v-input
      :isRight="isRight"
      id="return-adress-postal-code-number"
      :isRequired="true"
      :label="$t('label.return-address.postal-code')"
      :value="returnAddressPostalCode"
      :state="stateReturnAddressPostalCode && (!sameAddress || undefined)"
      :disabled="sameAddress"
      @onChange="(val) => handleChange('returnAddressPostalCode', val)"
    >
      <div slot="error">
        <slot name="returnAddressPostalCodeError" />
      </div>
    </v-input>

    <v-select
      :isRight="isRight"
      id="return-adress-country"
      :isRequired="true"
      :label="$t('label.return-address.country')"
      :options="countriesArr"
      :value="returnAddressCountry"
      :state="stateReturnAddressCountry"
      :error="$t('error.required')"
      :disabled="sameAddress"
      @onChange="(val) => handleChange('returnAddressCountry', val)"
    />
  </v-form-card>
</template>

<script>
import VFormCard from "@/components/VFormCard";
import VInput from "@/components/VInput";
import VSelect from "@/components/VSelect";

export default {
  name: "VReturnAddressForm",

  components: {
    VSelect,
    VFormCard,
    VInput,
  },

  data() {
    return {
      sameAddress: false,
      returnAddressStreet: null,
      returnAddressCity: null,
      returnAddressPostalCode: null,
      returnAddressCountry: null,
    };
  },

  props: {
    isRight: {
      type: Boolean,
      default: false,
    },
    pickupAddressStreet: {
      type: String,
      default: null,
    },
    pickupAddressCity: {
      type: String,
      default: null,
    },
    pickupAddressPostalCode: {
      type: String,
      default: null,
    },
    pickupAddressCountry: {
      type: String,
      default: null,
    },
    stateReturnAddressStreet: {
      type: Boolean,
      default: undefined,
    },
    stateReturnAddressCity: {
      type: Boolean,
      default: undefined,
    },
    stateReturnAddressPostalCode: {
      type: Boolean,
      default: undefined,
    },
    stateReturnAddressCountry: {
      type: Boolean,
      default: undefined,
    },
    countriesArr: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    handleCheck(isChecked) {
      if (isChecked) {
        this.returnAddressStreet = this.pickupAddressStreet;
        this.returnAddressCity = this.pickupAddressCity;
        this.returnAddressPostalCode = this.pickupAddressPostalCode;
        this.returnAddressCountry = this.pickupAddressCountry;
      } else {
        this.returnAddressStreet = null;
        this.returnAddressCity = null;
        this.returnAddressPostalCode = null;
        this.returnAddressCountry = null;
      }
    },

    handleChange(name, value) {
      this[name] = value;
      this.$emit("onChange", name, value);
    },
  },
};
</script>
