<template>
  <div class="product-table-container">
    <h5
      class="card-title"
      :class="{
        'text-left': !isRight,
        'text-right': isRight,
      }"
    >
      3.1 {{ $t("label.product.form.returned.title") }}
    </h5>

    <div class="product-table">
      <header class="product-table-head">
        <div class="product-table-row">
          <div class="product-table-cell">
            {{ $t("product.list.column.product") }}
          </div>

          <div class="product-table-cell">
            {{ $t("product.list.column.serial") }}
          </div>

          <div class="product-table-cell">
            {{ $t("product.list.column.description") }}
          </div>

          <div class="product-table-cell">
            {{ $t("product.list.column.edit") }}
          </div>

          <div class="product-table-cell">
            {{ $t("product.list.column.remove") }}
          </div>
        </div>
      </header>

      <div
        v-if="Array.isArray(products) && products.length"
        class="product-table-body"
      >
        <div
          class="product-table-row"
          v-for="(product, index) in products"
          :key="index"
        >
          <div class="product-table-cell">
            <span class="product-mobile-label"
              >{{ $t("product.list.column.serial") }}
            </span>
            {{ product.itemName }}
          </div>

          <div class="product-table-cell">
            <span class="product-mobile-label">{{
              $t("product.list.column.serial")
            }}</span>
            <div>
              <v-tooltip
                v-if="
                  Array.isArray(product.serialNumber) &&
                  product.serialNumber.length
                "
                :title="product.serialNumber.length"
                >{{ product.serialNumber.join(", ") }}</v-tooltip
              >
              <template v-else>0</template>
            </div>
          </div>

          <div class="product-table-cell">
            <span class="product-mobile-label">{{
              $t("product.list.column.description")
            }}</span>
            {{ getDescription(index) }}
            <span
              class="see-more-btn"
              v-if="isDescHidden(index)"
              @click="toggleDescription"
              >({{
                $t(
                  `product.list.column.see-${showDescription ? "less" : "more"}`
                )
              }})</span
            >
          </div>
          <div class="product-table-cell">
            <button type="button" @click="() => handleEdit(product)">
              <i class="far fa-edit text-info"></i>
            </button>
          </div>
          <div class="product-table-cell">
            <button type="button" @click="() => handleOpen(product)">
              <i class="far fa-window-close text-danger"></i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <b-modal id="remove-product-modal" size="sm" hide-header hide-footer>
      <div class="remove-product-modal">
        <h3>
          <i class="fas fa-exclamation-triangle"></i>
          {{ $t("are.you.sure") }}
        </h3>

        <footer>
          <b-button
            type="button"
            size="lg"
            variant="info"
            @click="handleRemove"
          >
            {{ $t("yes") }}
          </b-button>
          <b-button
            type="button"
            size="lg"
            variant="primary"
            @click="handleClose"
          >
            {{ $t("no") }}
          </b-button>
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>
import VTooltip from "@/components/VTooltip";

const MAX_DESCRIPTION_LENGTH = 200;

export default {
  name: "VAddedProductTable",

  components: {
    VTooltip,
  },

  data() {
    return {
      product: null,
      showDescription: false,
    };
  },

  props: {
    isRight: {
      type: Boolean,
      default: false,
    },
    products: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    handleChange(name, serial) {
      if (serial) this.$emit("onChange", { name, serial });
      else this.$emit("onChange", { name: null, serial: null });
    },

    toggleDescription() {
      this.showDescription = !this.showDescription;
    },

    handleOpen(product) {
      this.product = product;
      this.$bvModal.show("remove-product-modal");
    },

    handleClose() {
      this.$bvModal.hide("remove-product-modal");
    },

    handleEdit(product) {
      window.scrollTo(0, 650)
      this.product = product;
      this.$emit("onEdit", product);
    },

    handleRemove() {
      this.handleClose();
      this.$emit("onRemove", this.product);
    },

    isDescHidden(index) {
      return (
        this.products[index].returnDescription.length > MAX_DESCRIPTION_LENGTH
      );
    },

    getDescription(index) {
      const desc = this.products[index].returnDescription;
      return this.isDescHidden(index) && !this.showDescription
        ? desc.slice(0, MAX_DESCRIPTION_LENGTH) + "..."
        : desc;
    },
  },

  computed: {
    dir() {
      return this.isRight ? "rtl" : "ltr";
    },
  },
};
</script>

<style lang="scss" scoped>
.remove-product-modal {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 30px 20px;

  h3,
  footer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  h3 {
    color: #00406b;
    margin-bottom: 30px;
  }

  i {
    font-size: 16px;
    padding-right: 10px;
  }

  button {
    padding: 15px;
    margin: 0 5%;
    width: 40%;
  }
}

.see-more-btn {
  color: #00406b;
  margin-top: 5px;
  cursor: pointer;
}

.product-table-container {
  border-top: 1px solid #ced4da;
  padding-top: 40px;
  margin-top: 30px;
}

.product-table,
.product-table-head,
.product-table-body,
.product-table-row {
  display: flex;
  width: 100%;
}

.product-table,
.product-table-body {
  flex-direction: column;
}

.product-table-head,
.product-table-body {
  font-size: 14px;
}

.product-table-head {
  display: none;

  @media (min-width: 800px) {
    display: block;
    color: #00406b;
  }
}

.product-table-body {
  color: #2d2d2d;
}

.product-table-head .product-table-row {
  border-bottom: 2px solid #f1f1f1;
}

.product-table-4-6 {
  display: flex;
  flex-direction: column;
  align-items: center;

  > div {
    margin-bottom: 5px;
    width: 100%;
  }

  @media (min-width: 800px) {
    flex-direction: row;
    margin-bottom: 0;

    > div {
      &:first-child {
        width: 90%;
      }

      &:last-child {
        width: 10%;
      }
    }
  }
}

.product-table-product {
  border: 2px solid #f1f1f1;
  padding: 4px 10px;
  margin-bottom: 5px;
  min-height: 60px;

  @media (min-width: 800px) {
    margin-bottom: 20px;
  }
}

.product-table-row {
  border: 2px solid #f1f1f1;
  margin-bottom: 5px;
  min-height: 60px;
  flex-direction: column;

  @media (min-width: 800px) {
    border: 0;
    margin-bottom: 15px;
    flex-direction: row;
  }
}

.product-table-cell {
  display: flex;
  flex-direction: column;
  padding: 8px 10px;

  @media (min-width: 800px) {
    width: calc(45% - 160px);

    &:nth-child(2) {
      width: 10%;
    }

    &:nth-child(3) {
      width: 45%;
    }

    &:nth-child(4),
    &:nth-child(5) {
      width: 80px;
    }
  }
}

.product-table-cell button,
.product-table-cell button:hover {
  background-color: #f1f1f1;
  border: 0;
  font-size: 16px;
  width: 100%;
  height: 50px;
  cursor: pointer;

  @media (min-width: 800px) {
    background-color: transparent;
  }
}

.product-table-select {
  width: 100% !important;
}

.product-table-cell .form-group,
.product-table-cell .form-inline,
.product-table-cell .form-check {
  margin-bottom: 0;
}

.product-mobile-label {
  display: block;
  color: #00406b;

  @media (min-width: 800px) {
    display: none;
  }
}
</style>
