<template>
  <div>
    <div class="row">
      <div class="col col-xs-12 col-md-6">
        <v-select
          v-if="clearSelect"
          name="product-name"
          id="product-name"
          :label="$t('label.product.choose-from-list')"
          :options="productOptions"
          :dir="dir"
          @onChange="handleProduct"
        />
      </div>
    </div>

    <template v-if="product || isEditMode">
      <div class="alert alert-info mt-4 mb-4">
        <div
          class="mr-2 float-left"
          v-bind:class="{
            'mr-2': !isRight,
            'ml-2': isRight,
          }"
        >
          <svg
            width="14"
            height="14"
            aria-hidden="true"
            focusable="false"
            data-prefix="fal"
            data-icon="info-circle"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            class="svg-inline--fa fa-info-circle fa-w-16 fa-3x"
          >
            <path
              fill="currentColor"
              d="M256 40c118.621 0 216 96.075 216 216 0 119.291-96.61 216-216 216-119.244 0-216-96.562-216-216 0-119.203 96.602-216 216-216m0-32C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm-36 344h12V232h-12c-6.627 0-12-5.373-12-12v-8c0-6.627 5.373-12 12-12h48c6.627 0 12 5.373 12 12v140h12c6.627 0 12 5.373 12 12v8c0 6.627-5.373 12-12 12h-72c-6.627 0-12-5.373-12-12v-8c0-6.627 5.373-12 12-12zm36-240c-17.673 0-32 14.327-32 32s14.327 32 32 32 32-14.327 32-32-14.327-32-32-32z"
              class="far fa-info-circle"
            ></path>
          </svg>
        </div>

        <div class="d-table">
          {{ $t("product.form.info.serial.number") }}
        </div>
      </div>

      <div class="row">
        <div class="col col-xs-12 col-md-6">
          <v-select
            name="serial-numbers-search"
            id="serial-numbers-search"
            :label="$t('label.product.find.serials')"
            :options="serialOptions"
            :dir="dir"
            @onChange="handleSearch"
            @onInput="handleChangeSerial"
          />
        </div>
      </div>

      <b-form-checkbox
        v-if="serials.length > 1"
        id="all-serial-numbers"
        name="all-serial-numbers"
        class="mt-1 mb-3"
        v-model="allSerial"
        @change="() => handleValidation('selectedSerial')"
      >
        {{ $t("label.product.all.serials") }}
      </b-form-checkbox>

      <label
        v-if="selectedSerialState"
        :class="{ 'text-danger': selectedSerialState }"
      >
        <i class="fas fa-exclamation-triangle"></i>
        {{ $t("error.serial.number") }}
      </label>

      <div
        v-if="
          (product.serials &&
            selectedSerial.length !== product.serials.length) ||
          (product.serialNumber &&
            selectedSerial.length !== product.serialNumber.length)
        "
        class="mt-2 mb-4"
      >
        <b-button
          v-for="serial in selectedSerial"
          class="mb-1 mr-1"
          size="sm"
          variant="outline-secondary"
          :key="serial"
          @click="() => handleUnselectSerial(serial)"
          >{{ serial }} <span class="text-danger">&#x2715;</span></b-button
        >
      </div>

      <b-form-group v-if="serials" v-slot="{ ariaDescribedby }">
        <b-form-checkbox-group
          id="serials-list-checkbox-group"
          name="serials"
          class="serials-list"
          v-model="selectedSerial"
          :aria-describedby="ariaDescribedby"
          @change="() => handleValidation('selectedSerial')"
        >
          <b-form-checkbox
            v-for="serial in serials"
            :value="serial"
            :key="serial"
            >{{ serial }}
          </b-form-checkbox>
        </b-form-checkbox-group>
      </b-form-group>

      <b-pagination
        v-if="totalRows >= perPage"
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="center"
        first-number
        last-number
      />

      <div class="alert alert-info mt-4 mb-4">
        <div
          class="mr-2 float-left"
          v-bind:class="{
            'mr-2': !isRight,
            'ml-2': isRight,
          }"
        >
          <svg
            width="14"
            height="14"
            aria-hidden="true"
            focusable="false"
            data-prefix="fal"
            data-icon="info-circle"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            class="svg-inline--fa fa-info-circle fa-w-16 fa-3x"
          >
            <path
              fill="currentColor"
              d="M256 40c118.621 0 216 96.075 216 216 0 119.291-96.61 216-216 216-119.244 0-216-96.562-216-216 0-119.203 96.602-216 216-216m0-32C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm-36 344h12V232h-12c-6.627 0-12-5.373-12-12v-8c0-6.627 5.373-12 12-12h48c6.627 0 12 5.373 12 12v140h12c6.627 0 12 5.373 12 12v8c0 6.627-5.373 12-12 12h-72c-6.627 0-12-5.373-12-12v-8c0-6.627 5.373-12 12-12zm36-240c-17.673 0-32 14.327-32 32s14.327 32 32 32 32-14.327 32-32-14.327-32-32-32z"
              class="far fa-info-circle"
            ></path>
          </svg>
        </div>

        <div class="d-table">
          {{ $t("label.reason.service.text") }}
        </div>
      </div>

      <v-product-condition
        :isRight="isRight"
        :value="condition"
        :state="conditionState"
        @onSelect="(condition) => handleChange('condition', condition)"
      />

      <v-textarea
        :isRight="isRight"
        id="description"
        :value="description"
        :isRequired="true"
        :label="$t('label.reason.sales.description')"
        :state="!descriptionState"
        :error="$t('error.required')"
        @onChange="(description) => handleChange('description', description)"
      >
        <label slot="label" :class="{ 'text-danger': descriptionState }"
          ><i v-if="descriptionState" class="fas fa-exclamation-triangle"></i>
          {{ $t("label.reason.sales.description") }}
          <span class="text-danger pl-1">*</span></label
        >
      </v-textarea>

      <v-file
        :isRight="isRight"
        id="files-field"
        :value="files"
        :label="$t('label.reason.service.file.add')"
        @onChange="(files) => handleChange('files', files)"
      />
    </template>

<div id="addProductBtn">
    <b-button
      variant="success"
      :disabled="isDisabled"
      @click="handleAddProduct"
      :isRequired="true"
      block
    >
      <i class="fas fa-plus"></i> {{ $t("label.product.add.next-product") }}
      <span class="text-danger pl-1"> * </span>
    </b-button>
  </div>
  <div>
    <span class="text-danger pl-1 mt-3"> * {{ $t('error.required') }}</span>
  </div>

</div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";

import VSelect from "@/components/VSelect";
import VFile from "@/components/VFile";
import VTextarea from "@/components/VTextarea";
import VProductCondition from "../VProductCondition";

const CURRENT_PAGE = 1;
const PER_PAGE = 30;
const START = 0;
const END = 30;

export default {
  name: "VAddPartOrderForm",

  components: {
    VSelect,
    VFile,
    VTextarea,
    VProductCondition,
  },

  data() {
    return {
      currentPage: CURRENT_PAGE,
      perPage: PER_PAGE,
      start: START,
      end: END,
      product: null,
      serialQuery: "",
      selectedSerial: [],
      selectedSerialState: undefined,
      allSerial: [],
      files: [],
      description: "",
      descriptionState: undefined,
      condition: "",
      conditionState: undefined,
      clearSelect: true,
    };
  },

  props: {
    isRight: {
      type: Boolean,
      default: false,
    },
    isInvalid: {
      type: Boolean,
      default: undefined,
    },
    isEditMode: {
      type: Boolean,
      default: false,
    },
    returnReason: {
      type: String,
      default: null,
    },
    products: {
      type: Array,
      default: () => [],
    },
    selectedProducts: {
      type: Object,
      default: () => {},
    },
  },

  watch: {
    isInvalid() {
      this.handleValidation("description");
      this.handleValidation("condition");
      this.handleValidation("selectedSerial");
    },

    currentPage() {
      this.end = this.currentPage * this.perPage;
      this.start = this.end - this.perPage;
    },

    serialQuery() {
      this.currentPage = CURRENT_PAGE;
      this.perPage = PER_PAGE;
      this.start = START;
      this.end = END;
    },

    allSerial() {
      if (this.allSerial.length) this.selectedSerial = this.product.serials;
      else this.selectedSerial = [];
    },

    selectedProducts() {
      this.handleSelected();
    },

    productOptions() {
      this.clearSelect = false;
    },

    clearSelect(val) {
      if (!val) {
        setTimeout(() => {
          this.clearSelect = true;
        }, 0);
      }
    },
  },

  methods: {
    handleChangeSerial(val) {
      this.selectedSerial = []
      this.selectedSerial.push(val)
    },
    handleUnselectSerial(number) {
      this.selectedSerial = this.selectedSerial.filter(
        (serial) => serial !== number
      );
    },

    handleChange(name, value) {
      this[name] = value;
      this.handleValidation(name);
    },

    handleSearch(query) {
      this.serialQuery = query;
    },

    handleProduct(value) {
      this.handleClear();
      this.product = this.products.find((prod) => prod.name === value);
    },

    handleSelected() {
      this.descriptionState = undefined;
      this.conditionState = undefined;
      this.description = this.selectedProducts.returnDescription;
      this.condition = this.selectedProducts.itemState;
      this.files = this.selectedProducts.files;
      this.selectedSerial = this.selectedProducts.serialNumber;
      this.product =
        this.products.length > 1
          ? this.products.find(({ id }) => id === this.selectedProducts.id)
          : this.products[0] || null;
    },

    handleClear() {
      this.currentPage = CURRENT_PAGE;
      this.perPage = PER_PAGE;
      this.start = START;
      this.end = END;
      this.product = null;
      this.serialQuery = "";
      this.selectedSerial = [];
      this.allSerial = [];
      this.files = [];
      this.description = "";
      this.condition = "";

      this.descriptionState = undefined;
      this.conditionState = undefined;
      this.selectedSerialState = undefined;
    },

    handleAddProduct() {
      if (this.isDisabled) return;

      this.handleValidation("description");
      this.handleValidation("condition");
      this.handleValidation("selectedSerial");

      if (
        this.descriptionState ||
        this.conditionState ||
        this.selectedSerialState
      )
        return;

      this.$emit("onAddProduct", {
        id: this.product?.id || uuidv4(),
        partNumber: this.product?.partNumber,
        itemName: this.product?.name || this.product?.itemName,
        serialNumber: this.selectedSerial,
        returnReason: this.returnReason,
        returnDescription: this.description,
        itemState: this.condition,
        files: this.files,
        parcelPhoto: null,
        protocolPhoto: null,
        carrierName: null,
      });

      this.handleClear();
    },

    handleValidation(name) {
      if (this[name].toString()) this[`${name}State`] = false;
      else this[`${name}State`] = true;
    },
  },

  computed: {
    isDisabled() {
      return !this.product && !this.isEditMode;
    },

    dir() {
      return this.isRight ? "rtl" : "ltr";
    },

    serialOptions() {
      return this.product?.serials || [];
    },

    productOptions() {
      return this.products.map((product) => product.name);
    },

    totalRows() {
      return this.serialQuery
        ? this.serials?.length || 0
        : this.product?.serials?.length || 0;
    },

    serials() {
      const serials = this.product?.serials || this.product?.serialNumber;

      if (this.serialQuery) {
        return (
          serials
            ?.filter((serial) =>
              serial
                ?.toString()
                ?.toUpperCase()
                ?.includes(this.serialQuery?.toString()?.toUpperCase())
            )
            ?.slice(this.start, this.end) || []
        );
      }

      return serials?.slice(this.start, this.end) || [];
    },
  },
};
</script>

<style scoped>
  #addProductBtn {
    display: flex;
    justify-content: center;
  }
</style>