import { render, staticRenderFns } from "./VAddPartOrderForm.vue?vue&type=template&id=541653a6&scoped=true&"
import script from "./VAddPartOrderForm.vue?vue&type=script&lang=js&"
export * from "./VAddPartOrderForm.vue?vue&type=script&lang=js&"
import style0 from "./VAddPartOrderForm.vue?vue&type=style&index=0&id=541653a6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "541653a6",
  null
  
)

export default component.exports