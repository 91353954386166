<template>
  <div>
    <span class="tooltip-trigger">{{ title }}</span>
    <div class="tooltip-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "VTooltip",

  props: {
    title: {
      type: Number,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
.tooltip-trigger {
  cursor: pointer;
}

.tooltip-content {
  display: none;
  position: absolute;
  box-shadow: 0px 0px 15px 5px rgba(#000, 0.15);
  background-color: #fff;
  color: #333;
  padding: 0.5rem 0.75rem;
  z-index: 99;
  font-size: 12px;
  text-align: justify;
  line-height: 1.4;
  max-width: 360px;
}

.tooltip-trigger:hover + .tooltip-content {
  display: block;
}
</style>
