<template>
  <v-form-card
    id="addProductFormType"
    :isRight="isRight"
    :isRequired="true"
    :title="title"
    :error="null"
  >
    <div class="radio-list">
      <b-form-group v-slot="{ ariaDescribedby }">
        <b-form-radio
          v-model="selected"
          :aria-describedby="ariaDescribedby"
          name="whole-form-radio"
          value="WHOLE_ORDER"
          :state="state"
          :disabled="
            isDisabled || (selected === 'PART_ORDER' && !!getProducts.length)
          "
          >{{ $t("label.product.form.checkbox.whole") }}</b-form-radio
        >
        <b-form-radio
          v-model="selected"
          :aria-describedby="ariaDescribedby"
          name="part-form-radio"
          value="PART_ORDER"
          :state="state"
          :disabled="
            isDisabled || (selected === 'WHOLE_ORDER' && !!getProducts.length)
          "
          >{{ $t("label.product.form.checkbox.part") }}
        </b-form-radio>
      </b-form-group>
    </div>

    <v-add-whole-order-form
      v-if="
        selected === 'WHOLE_ORDER' && (!getProducts.length || enableEditMode)
      "
      :isRight="isRight"
      :isInvalid="isInvalid"
      :returnReason="returnReason"
      :products="products"
      :selectedProducts="selectedProducts"
      @onAddOrder="handleAddOrder"
    />

    <v-add-part-order-form
      v-else-if="selected === 'PART_ORDER'"
      :isRight="isRight"
      :isEditMode="enableEditMode"
      :isInvalid="isInvalid"
      :returnReason="returnReason"
      :products="products"
      :selectedProducts="selectedProducts"
      @onAddProduct="handleAddProduct"
    />

    <v-added-order-table
      v-if="selected === 'WHOLE_ORDER' && getProducts.length"
      :products="getProducts"
      @onEdit="handleEditOrder"
      @onRemove="handleRemoveOrder"
    />

    <v-added-product-table
      v-if="selected === 'PART_ORDER' && getProducts.length"
      :products="getProducts"
      @onEdit="handleEditProduct"
      @onRemove="handleRemoveProduct"
    />
  </v-form-card>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";

import VFormCard from "@/components/VFormCard";
import VAddWholeOrderForm from "./VAddWholeOrderForm";
import VAddPartOrderForm from "./VAddPartOrderForm";
import VAddedProductTable from "../VAddedProductTable";
import VAddedOrderTable from "../VAddedOrderTable";

export default {
  name: "VAddProductForm",

  data() {
    return {
      selected: "",
      selectedProducts: null,
      enableEditMode: false,
    };
  },

  props: {
    isRight: {
      type: Boolean,
      default: false,
    },
    isInvalid: {
      type: Boolean,
      default: undefined,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    returnReason: {
      type: String,
      default: null,
    },
    state: {
      type: Boolean,
      default: undefined,
    },
  },

  watch: {
    selected(val) {
      this.$emit("onSelect", val);
    },
  },

  methods: {
    ...mapMutations("products", [
      "ADD_ORDER",
      "REMOVE_ORDER",
      "ADD_PRODUCT",
      "REMOVE_PRODUCT",
    ]),

    handleAddOrder(product) {
      this.ADD_ORDER(product);
      this.enableEditMode = false;
    },

    handleEditOrder() {
      this.selectedProducts = this.getProducts.length
        ? this.getProducts[0]
        : null;
      this.enableEditMode = true;
    },

    handleRemoveOrder(product) {
      this.REMOVE_ORDER(product);
      this.enableEditMode = false;
    },

    handleAddProduct(product) {
      this.ADD_PRODUCT(product);
      this.enableEditMode = false;
    },

    handleEditProduct(product) {
      this.selectedProducts = product;
      this.enableEditMode = true;
    },

    handleRemoveProduct(product) {
      this.REMOVE_PRODUCT(product);
      this.enableEditMode = false;
    },
  },

  computed: {
    ...mapGetters("invoice", ["getFilteredProducts"]),
    ...mapGetters("products", { getProducts: "getProducts" }),

    title() {
      return "3. " + this.$t("label.product.form.title");
    },

    products() {
      return this.enableEditMode && this.getProducts.length
        ? this.getProducts
        : this.getFilteredProducts;
    },
  },

  components: {
    VFormCard,
    VAddWholeOrderForm,
    VAddPartOrderForm,
    VAddedProductTable,
    VAddedOrderTable,
  },
};
</script>
