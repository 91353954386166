<template>
  <v-form-card
    id="complaintType"
    :isRight="isRight"
    :isRequired="true"
    :title="title"
    :error="null"
  >
    <div class="radio-list">
      <b-form-group v-slot="{ ariaDescribedby }">
        <b-form-radio
          v-if="showGuarantee"
          v-model="selected"
          :aria-describedby="ariaDescribedby"
          name="guarantee-radios"
          value="GUARANTEE"
          :state="state"
          >{{ $t("label.complaint-type.guarantee") }}</b-form-radio
        >
        <b-form-radio
          v-model="selected"
          :aria-describedby="ariaDescribedby"
          name="warranty-radios"
          value="WARRANTY"
          :state="state"
          >{{ $t("label.complaint-type.warranty") }}</b-form-radio
        >
        <b-form-radio
          v-model="selected"
          :aria-describedby="ariaDescribedby"
          name="return-radios"
          value="RETURN"
          :state="state"
          >{{ $t("label.complaint-type.return") }}</b-form-radio
        >
        <b-form-radio
          v-model="selected"
          :aria-describedby="ariaDescribedby"
          name="transport-damage-radios"
          value="TRANSPORTATION_DEMAGE"
          :state="state"
          >{{ $t("label.complaint-type.transport-damage") }}</b-form-radio
        >
      </b-form-group>
    </div>
  </v-form-card>
</template>

<script>
import VFormCard from "@/components/VFormCard";

export default {
  name: "VComplaintTypeForm",

  data() {
    return {
      selected: "",
    };
  },

  props: {
    isRight: {
      type: Boolean,
      default: false,
    },
    state: {
      type: Boolean,
      default: undefined,
    },
    currentLang: {
      type: String,
      default: null,
    },
  },

  watch: {
    selected(val) {
      this.$emit("onSelect", val);
    },

    currentLang() {
      if (this.currentLang !== "pl" && this.selected === "GUARANTEE") {
        this.selected = "";
        this.$emit("onSelect", "");
      }
    },
  },

  computed: {
    title() {
      return "1. " + this.$t("label.complaint-type.form.title");
    },

    showGuarantee() {
      return this.currentLang === "pl";
    },
  },

  components: {
    VFormCard,
  },
};
</script>
