<template>
  <div
    class="form-group"
    :class="{
      'text-left': !isRight,
      'text-right': isRight,
    }"
    role="group"
  >
    <div>
      <span
        v-if="label && !this.$slots.label"
        :for="id"
        :class="{ 'text-danger': state === false }"
      >
        {{ label }} <span v-if="isRequired" class="text-danger pl-1">*</span>
      </span>
      <slot name="label" />

      <popper
        v-if="popoverText || $slots['popover']"
        trigger="hover"
        :options="{
          placement: isRight ? 'right' : 'left',
        }"
      >
        <div class="popper">
          <div class="img-fluid-container">
            <slot name="popover"></slot>
          </div>
          {{ popoverText }}
        </div>

        <span
          slot="reference"
          class="far fa-question-circle cursor-pointer d-inline-block"
          :class="{
            'mr-2': isRight,
            'mr-1 ml-0': !isRight,
          }"
        />
      </popper>
    </div>

    <div class="file-field" :class="{ 'file-field-disabled': isDisabled }">
      <label :for="id"
        >{{ $t("product.form.drag.drop.file") }}
        <span class="text-info">{{ $t("product.form.browse") }}</span></label
      >
      <b-form-file
        :id="id"
        :state="state"
        :aria-describedby="`${id}-live-help ${id}-feedback`"
        :placeholder="placeholder"
        :disabled="isDisabled"
        :accept="accept.join(', ')"
        multiple
        plain
        @input="handleInput"
      />
      <!-- v-model="internalValue" -->
    </div>

    <b-form-invalid-feedback
      v-if="error && !this.$slots.error"
      :id="`${id}-feedback`"
    >
      {{ error }}
    </b-form-invalid-feedback>

    <slot name="error" />

    <div v-if="internalValue.length" class="uploaded-files">
      <div
        class="uploaded-file"
        v-for="(file, index) in internalValue"
        :key="index"
      >
        {{ file.name }}
        <span class="text-danger" @click="() => handleRemove(file)"
          >&#10005;</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import Popper from "vue-popperjs";

const MAX_FILES_LENGTH = 5;

export default {
  name: "VField",

  data() {
    return {
      internalValue: [],
      accept: [
        "image/jpeg",
        "image/pjpeg",
        "image/png",
        "video/mp4",
        "video/quicktime",
        "text/plain",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/excel",
        "application/vnd.ms-excel",
        "application/x-excel",
        "application/x-msexcel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/pdf",
        "image/bmp",
        "image/x-windows-bmp",
        "audio/mpeg3",
        "audio/mp3",
        "audio/mpeg",
        "audio/x-mpeg-3",
        "video/mpeg",
        "video/x-mpeg",
      ],
    };
  },

  props: {
    isRight: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      isRequired: true,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    value: {
      type: Array,
      default: () => [],
    },
    state: {
      type: Boolean,
      default: undefined,
    },
    error: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: undefined,
    },
    popoverText: {
      type: String,
      default: null,
    },
  },

  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val) this.internalValue = val;
      },
    },
  },

  methods: {
    handleInput(value) {
      const uploadedFiles = value.filter((v) => v.size < 5242880); // 5MB
      const files = [...this.internalValue, ...uploadedFiles].splice(
        0,
        MAX_FILES_LENGTH
      );
      this.internalValue = files;

      this.$emit("onChange", files);
    },

    handleRemove(file) {
      const fileIndex = this.internalValue.indexOf(file);
      this.internalValue.splice(fileIndex, 1);
      this.$emit("onChange", this.internalValue);
    },
  },

  computed: {
    isDisabled() {
      return this.disabled || this.internalValue.length >= MAX_FILES_LENGTH;
    },
  },

  components: {
    popper: Popper,
  },
};
</script>

<style lang="scss" scoped>
.file-field {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed #ced4da;
  margin-bottom: 15px;
  cursor: pointer;
  width: 100%;
  height: 80px;

  &-disabled {
    background-color: #f9f9f9;
  }

  label {
    color: #00406b;
    margin: 0;
    cursor: pointer;
  }

  input {
    position: absolute;
    width: 100%;
    height: 60px;
    z-index: 1;
    opacity: 0;
    display: block;
    cursor: pointer;
  }
}

.file-field-label,
.uploaded-file {
  font-family: "Lato", sans-serif;
  font-size: 14px;
  line-height: 17px;
  color: #00406b;
  display: inline-block;
  margin-bottom: 10px;
}

.uploaded-files {
  margin-bottom: 30px;
}

.uploaded-file {
  background: rgba(#00aeef, 0.05);
  display: flex;
  padding: 15px 20px;

  span {
    font-size: 16px;
    padding: 0 5px;
    margin-left: auto;
    cursor: pointer;
  }
}
</style>
