<template>
  <div>
    <v-product-table :products="products" :isRight="isRight" />

    <div class="alert alert-info mt-4 mb-4">
      <div
        class="mr-2 float-left"
        v-bind:class="{
          'mr-2': !isRight,
          'ml-2': isRight,
        }"
      >
        <svg
          width="14"
          height="14"
          aria-hidden="true"
          focusable="false"
          data-prefix="fal"
          data-icon="info-circle"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          class="svg-inline--fa fa-info-circle fa-w-16 fa-3x"
        >
          <path
            fill="currentColor"
            d="M256 40c118.621 0 216 96.075 216 216 0 119.291-96.61 216-216 216-119.244 0-216-96.562-216-216 0-119.203 96.602-216 216-216m0-32C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm-36 344h12V232h-12c-6.627 0-12-5.373-12-12v-8c0-6.627 5.373-12 12-12h48c6.627 0 12 5.373 12 12v140h12c6.627 0 12 5.373 12 12v8c0 6.627-5.373 12-12 12h-72c-6.627 0-12-5.373-12-12v-8c0-6.627 5.373-12 12-12zm36-240c-17.673 0-32 14.327-32 32s14.327 32 32 32 32-14.327 32-32-14.327-32-32-32z"
            class="far fa-info-circle"
          ></path>
        </svg>
      </div>

      <div class="d-table">
        {{ $t("label.reason.service.text") }}
      </div>
    </div>

    <v-product-condition
      :isRight="isRight"
      :value="condition"
      :state="conditionState"
      @onSelect="(condition) => handleChange('condition', condition)"
    />

    <v-textarea
      :isRight="isRight"
      id="description"
      :value="description"
      :isRequired="true"
      :state="!descriptionState"
      :error="$t('error.required')"
      @onChange="(description) => handleChange('description', description)"
    >
      <label slot="label" :class="{ 'text-danger': descriptionState }"
        ><i v-if="descriptionState" class="fas fa-exclamation-triangle"></i>
        {{ $t("label.reason.sales.description") }}
        <span class="text-danger pl-1">*</span></label
      >
    </v-textarea>

    <v-file
      :isRight="isRight"
      id="files-field"
      :value="files"
      :label="$t('label.reason.service.file.add')"
      @onChange="(files) => handleChange('files', files)"
    />

    <b-button
            variant="success"
            :isRight="isRight"
            :state="conditionState"
            :isRequired="true"
            @click="handleAddProduct"
            block
    >
      <i class="fas fa-plus"></i> {{ $t("label.product.add.next-product") }}
      <span class="text-danger pl-1"> * </span>
    </b-button>

    <div>
      <span class="text-danger pl-1 mt-3"> * {{ $t('error.required') }}</span>
    </div>
  </div>
</template>

<script>
import VFile from "@/components/VFile";
import VTextarea from "@/components/VTextarea";
import VProductTable from "./VProductTable";
import VProductCondition from "../VProductCondition";

export default {
  name: "VAddWholeOrderForm",

  components: {
    VProductCondition,
    VProductTable,
    VTextarea,
    VFile,
  },

  data() {
    return {
      description: "",
      descriptionState: undefined,
      condition: "",
      conditionState: undefined,
      files: [],
    };
  },

  props: {
    isRight: {
      type: Boolean,
      default: false,
    },
    isInvalid: {
      type: Boolean,
      default: undefined,
    },
    returnReason: {
      type: String,
      default: null,
    },
    products: {
      type: Array,
      default: () => [],
    },
    selectedProducts: {
      type: Object,
      default: () => {},
    },
  },

  watch: {
    description() {
      this.handleValidation("description");
    },

    condition() {
      this.handleValidation("condition");
    },

    isInvalid() {
      this.handleValidation("description");
      this.handleValidation("condition");
    },
  },

  methods: {
    handleChange(name, value) {
      this[name] = value;
    },

    handleClear() {
      this.product = null;
      this.description = "";
      this.descriptionState = undefined;
      this.condition = "";
      this.conditionState = undefined;
    },

    handleAddProduct() {
      this.handleValidation("description");
      this.handleValidation("condition");

      if (this.descriptionState || this.conditionState) return;

      const lines = this.products.map(
        ({ partNumber, name, itemName, serials, serialNumber }) => ({
          partNumber,
          itemName: name || itemName,
          serialNumber: serials || serialNumber,
          returnReason: this.returnReason,
          returnDescription: this.description,
          itemState: this.condition,
          files: this.files,
          parcelPhoto: null,
          protocolPhoto: null,
          carrierName: null,
        })
      );

      this.$emit("onAddOrder", lines);

      this.handleClear();
    },

    handleValidation(name) {
      if (this[name]) this[`${name}State`] = false;
      else this[`${name}State`] = true;
    },
  },

  mounted() {
    if (this.selectedProducts) {
      // this.descriptionState = false;
      // this.conditionState = false;
      this.description = this.selectedProducts.returnDescription;
      this.condition = this.selectedProducts.itemState;
      this.files = this.selectedProducts.files;
    }
  },
};
</script>
