<template>
  <div
    class="form-group"
    :class="{
      'text-left': !isRight,
      'text-right': isRight,
      'is-select-invalid': state === false,
    }"
    role="group"
  >
    <div>
      <label
        v-if="label && !this.$slots.label"
        :for="id"
        :class="{ 'text-danger': state === false }"
      >
        {{ label }} <span v-if="isRequired" class="text-danger pl-1">*</span>
      </label>
      <slot name="label" />

      <popper
        v-if="popoverText || $slots['popover']"
        trigger="hover"
        :options="{
          placement: isRight ? 'right' : 'left',
        }"
      >
        <div class="popper">
          <div class="img-fluid-container">
            <slot name="popover"></slot>
          </div>
          {{ popoverText }}
        </div>

        <span
          slot="reference"
          class="far fa-question-circle cursor-pointer d-inline-block"
          :class="{
            'mr-2': isRight,
            'mr-1 ml-0': !isRight,
          }"
        />
      </popper>
    </div>

    <VVSelect
      :options="options"
      v-model="internalValue"
      :id="id"
      :name="id"
      :placeholder="localPlaceholder"
      :dir="isRight === 'rtl' ? 'rtl' : 'ltr'"
      :disabled="disabled"
      @search:blur="togglePlaceholder"
      @search:focus="togglePlaceholder"
      @input="onInput"
    />

    <div
      v-if="state === false && error && !this.$slots.error"
      :id="`${id}-feedback`"
      class="d-block invalid-feedback"
    >
      {{ error }}
    </div>

    <slot name="error" />
  </div>
</template>

<script>
import VSelect from "vue-select";
import Popper from "vue-popperjs";

export default {
  name: "VSelect",

  components: {
    popper: Popper,
    VVSelect: VSelect,
  },

  data() {
    return {
      showPlaceholder: true,
      internalValue: null,
    };
  },

  props: {
    isRight: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      isRequired: true,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    options: {
      type: Array,
      default: () => [],
    },
    value: {
      type: String,
      default: null,
    },
    state: {
      type: Boolean,
      default: undefined,
    },
    error: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: undefined,
    },
    popoverText: {
      type: String,
      default: null,
    },
  },

  watch: {
    internalValue(val) {
      this.$emit("onChange", val);
    },

    value(val) {
      if (val) this.internalValue = val;
    },
  },

  methods: {
    togglePlaceholder() {
      this.showPlaceholder = !this.showPlaceholder;
    },
    onInput(val) {
      this.$emit('onInput', val);
    },
  },

  computed: {
    localPlaceholder() {
      return this.showPlaceholder
        ? this.placeholder
        : this.$t("label.search.invoice.search");
    },
  },
};
</script>
