<template>
  <v-form-card
    id="complaintMethod"
    :isRight="isRight"
    :isRequired="true"
    :title="title"
    :error="null"
  >
    <div class="radio-list">
      <b-form-group v-slot="{ ariaDescribedby }">
        <b-form-radio
          v-model="selected"
          :aria-describedby="ariaDescribedby"
          name="service-radios"
          value="REPAIR"
          :state="state"
          :disabled="isRepairDisabled"
          >{{ $t("label.complaint-method.service") }}</b-form-radio
        >
        <b-form-radio
          v-model="selected"
          :aria-describedby="ariaDescribedby"
          name="replacement-radios"
          value="EXCHANGE"
          :state="state"
          >{{ $t("label.complaint-method.replacement") }}</b-form-radio
        >
        <b-form-radio
          v-model="selected"
          :aria-describedby="ariaDescribedby"
          name="cashback-radios"
          value="CASH_BACK"
          :state="state"
          >{{ $t("label.complaint-method.cashback") }}</b-form-radio
        >
      </b-form-group>
    </div>
  </v-form-card>
</template>

<script>
import VFormCard from "@/components/VFormCard";

export default {
  name: "VComplaintMethodForm",

  data() {
    return {
      selected: "",
    };
  },

  props: {
    isRight: {
      type: Boolean,
      default: false,
    },
    isRepairDisabled: {
      type: Boolean,
      default: false,
    },
    state: {
      type: Boolean,
      default: undefined,
    },
  },

  watch: {
    selected(val) {
      this.$emit("onSelect", val);
    },

    isRepairDisabled(val) {
      if (val && this.selected === "REPAIR") this.selected = "";
    },
  },

  computed: {
    title() {
      return "2. " + this.$t("label.complaint-method.form.title");
    },
  },

  components: {
    VFormCard,
  },
};
</script>
